import i18n from "@/i18n.js"

const columnDefs = [
  {
    headerName: `${i18n.t('oncost.company')}`,
    traslateName: "oncost.company",
    field: "company",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('company.version')}`,
    traslateName: "company.version",
    field: "version",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('sms.estado')}`,
    traslateName: "sms.estado",
    field: "status",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('sms.message')}`,
    traslateName: "sms.message",
    field: "message",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('servicios_tab.actions')}`,
    traslateName: 'servicios_tab.actions',
    sortable: false,
    hide: false,
    filter: false,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    },
    cellRenderer: 'actionssms'
  }
]


export default {
  columnDefs
}