<template lang="pug">
  vue-index
</template>

<script>

import SMSIndex from "@/components/sms/Index"

const NAME = "SMSIndex"

export default {
  name: NAME,
  components: {
    "vue-index": SMSIndex
  }
}
</script>