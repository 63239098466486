<template>
  <div id="div-with-loading-status" class="vs-con-loading__container" v-if="$can('read', 'status')">
    <ag-grid-table
      :animateRows="true"
      :suppressRowClickSelection="true"
      :columnDefs="chartConfig.columnDefs"
      :rowData="chartConfig.rowData"
      :gridOptions="gridOptions"
      :sizeTofit="true"
      rowSelection="multiple"
      @RefreshTableData="RefreshTableData"
    >
      <template v-if="isActionBar()" v-slot:actionBar>
        <ag-action-bar
          :is_add="$can('create', 'sms')"
          :is_edit="$can('update', 'sms')"
          :is_trash="$can('delete', 'sms')"
          :is_company="$can('read', 'company')"
          :is_activate="false"
          :is_deactivate="false"
          :is_exportable="false"
          :is_import="false"
          @add="modalCreateStatus"
          @edit="onBtnEdit"
          @trash="onBtnTrash"
          @IsCompany="IsCompany"
        ></ag-action-bar>
      </template>
    </ag-grid-table>
    <vue-form
      ref="FormSms"
      :popupActivo="popupActivo"
      :sms="currentSms"
      @refreshTable="getSmsAll"
      @closePopup="closePo"
    ></vue-form>
    <vue-form-company
      v-if="popupActivoUpdate"
      ref="FormUpdateSms"
      :popupActivo="popupActivoUpdate"
      :dataSms="dataSms"
      @refreshTableUpdate="getSmsAll"
      @closePopup="closePoUpdate"
    ></vue-form-company>
  </div>
</template>
<script>
import { validatePermission } from "@/Ability";
import { mapState } from "vuex";
import smsApi from "@/api/sms/Sms";
import SmsForm from "@/components/sms/Form";
import SmsUpdateCompanyForm from "@/components/sms/UpdateCompany";
import AgGridTable from "@/components/tableAgGrid/Index";
import ActionsBar from "@/components/tableAgGrid/ActionsBar";
import FieldsSms from "@/fields/sms/fieldsSms";
import { translateTableFields } from "@/filter";

const NAME = "StatusIndex";

export default {
  name: NAME,
  components: {
    "vue-form": SmsForm,
    "vue-form-company": SmsUpdateCompanyForm,
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
  },
  data() {
    return {
      popupActivo: false,
      popupActivoUpdate: false,
      currentSms: null,
      scopes: [],
      dataSms: null,
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` },
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsSms.columnDefs),
        rowData: [],
      },
    };
  },
  computed: {
    ...mapState("sms", ["smsEdit", "smsDelete"]),
  },
  watch: {
    smsEdit(val) {
      if (val != null) {
        this.popupActivo = !this.popupActivo;
        this.currentSms = this.smsEdit;
      }
    },
    smsDelete(val) {
      if (val != null) {
        this.onBtnTrashInline(val);
      }
    },
  },
  methods: {
    isActionBar() {
      if (
        this.$can("read", "sms") ||
        this.$can("create", "sms") ||
        this.$can("update", "sms") ||
        this.$can("delete", "sms")
      ) {
        return true;
      } else {
        return false;
      }
    },
    RefreshTableData() {
      this.asignColumnDefs();
    },
    asignColumnDefs() {
      this.chartConfig.columnDefs = [];
      setTimeout(() => {
        this.chartConfig.columnDefs = translateTableFields(
          FieldsSms.columnDefs
        );
      }, 50);
    },
    IsCompany() {
      let data = this.gridOptions.api.getSelectedNodes();
      if (data.length > 0) {
        if (data[0].data.id_sms == null) {
          this.$vs.notify({
            text: this.$t("sms.nonEditablesms"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.popupActivoUpdate = true;
          this.dataSms = data;
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onBtnTrashInline(data) {
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("sms.delete_sms"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.value) {
          if (data.id_sms == null) {
            this.$vs.notify({
              text: this.$t("sms.nonRemovablesms"),
              color: "danger",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            if (data.id_sms) {
              smsApi
                .smsDelete(data.id_sms)
                .then(() => {
                  this.getSmsAll();
                })
                .catch(() => {
                  this.$vs.notify({
                    text: this.$t("sms.nonRemovablesms"),
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                });
            }
          }
        } else {
          this.$store.dispatch("sms/set_delete_sms", null);
        }
      });
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes();
      if (data.length > 0) {
        if (data[0].data.id_sms == null) {
          this.$vs.notify({
            text: this.$t("sms.nonEditablesms"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.popupActivo = true;
          this.currentSms = data[0].data;
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onBtnTrash() {
      let data = this.gridOptions.api.getSelectedNodes().map((x) => {
        return x.data.id_sms;
      });
      if (data.length > 0) {
        this.showDeleteAlert((res) => {
          if (res.value == true) {
            let smsData = {
              sms: data,
            };
            smsApi
              .smsDeleteMultiple(smsData)
              .then(() => {
                this.getSmsAll();
              })
              .catch(() => {
                this.$vs.notify({
                  text: this.$t("sms.nonRemovablesms"),
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              });
          }
        });
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    showDeleteAlert(callback) {
      this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("sms.delete_sms"),
        showCancelButton: true,
        showConfirmButton: true,
      }).then(callback);
    },
    closePo(val) {
      if (val != null) {
        this.$refs.FormSms.clear();
      }
      this.popupActivo = false;
      this.currentSms = null;
    },
    closePoUpdate(val) {
      if (val != null) {
        this.$refs.FormUpdateSms.clear();
      }
      this.popupActivoUpdate = false;
      this.currentSms = null;
    },
    modalCreateStatus() {
      this.popupActivo = true;
      this.currentSms = null;
    },
    getSmsAll() {
      this.$vs.loading({
        container: "#div-with-loading-status",
        type: "material",
        scale: 1,
      });
      smsApi
        .getSmsAll()
        .then((response) => {
          this.chartConfig.rowData = response.data;
          // setTimeout(() => {
          //   let allColumnIds = []
          //   this.gridOptions.columnApi.getAllColumns().forEach(column => {
          //     allColumnIds.push(column.colId)
          //   })
          //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
          // }, 50)
        })
        .finally(() => {
          this.$vs.loading.close("#div-with-loading-status > .con-vs-loading");
        });
    },
  },
  mounted() {
    this.getSmsAll();
  },
  created() {
    validatePermission(this, "read", "status", "Home");
  },
};
</script>