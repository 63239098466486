<template>
  <vs-popup
    id="popupStatusForm"
    classContent="popup-example"
    :title="$t('status.form')"
    :active.sync="popup"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2 sm:w-1/2">
        <label class="vs-select--label">{{ $t('users.company') }}</label>
        <v-select
          :options="companies"
          label="name"
          :clearable="false"
          name="company"
          :data-vv-as="$t('users.company')"
          v-validate="'required'"
          v-model="company"
          :placeholder="$t('users.company')"
          autocomplete="off"
        >
          <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
        </v-select>
        <span
          class="text-danger text-sm"
          v-show="errors.has('company')"
        >{{ errors.first('company') }}</span>
      </div>
      <div class="vx-col w-full mb-2 sm:w-1/2">
        <label class="vs-select--label">{{ $t('users.status') }}</label>
        <v-select
          :options="statuses"
          label="name"
          name="estado"
          :data-vv-as="$t('users.status')"
          v-validate="'required'"
          :clearable="false"
          :placeholder="$t('users.status')"
          v-model="status"
          autocomplete="off"
        >
          <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
          <div slot="no-options">{{ $t('sms.select_company') }}</div>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('estado')">{{ errors.first('estado') }}</span>
      </div>
      <div class="vx-col w-full mb-2 sm:w-1/1">
        <tiny-mce
          v-if="popupActivo"
          id="d1"
          ref="tm"
          name="sms"
          v-validate="'required'"
          api-key="no-api-key"
          :init="initObj"
          v-model="infoData"
          :plugins="plugins"
          :toolbar="toolbar"
        ></tiny-mce>
        <span class="text-danger text-sm" v-show="errors.has('sms')">{{ errors.first('sms') }}</span>
      </div>
    </div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <vs-button
          class="mt-6 block offset-3"
          color="primary"
          type="filled"
          @click="setSave"
        >{{ $t('devices.save') }}</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import HTTP from "@/http-common";
import ErrorNotify from "@/components/errors/Errors";
import i18n from "@/i18n.js";
import StatusServices from "@/api/status/Status";
import editor from "@tinymce/tinymce-vue";

export default {
  props: {
    sms: {
      type: Object,
      required: false,
    },
    popupActivo: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    "tiny-mce": editor,
  },
  data() {
    return {
      initObj: {
        height: 300,
        entity_encoding: "raw",
        invalid_elements: 'strong,b,em,i,span,div',
        menubar: false,
        setup: function (editor) {
          editor.ui.registry.addButton("nombre", {
            text: "NOMBRE",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[NOMBRE]]");
            },
          }),
            editor.ui.registry.addButton("apellido", {
              text: "APELLIDO",
              onAction: function (_) {//eslint-disable-line
                editor.insertContent("[[APELLIDO]]");
              },
            }),
            editor.ui.registry.addButton("pedido", {
              text: "NUMERO_PEDIDO",
              onAction: function (_) {// eslint-disable-line
                editor.insertContent("[[NUMERO_PEDIDO]]");
              },
            }),
            editor.ui.registry.addButton("guia", {
              text: "GUIA_PEDIDO",
              onAction: function (_) {// eslint-disable-line
                editor.insertContent("[[GUIA_PEDIDO]]");
              },
            }),
            editor.ui.registry.addButton("estado", {
              text: "ESTADO",
              onAction: function (_) {// eslint-disable-line
                editor.insertContent("[[ESTADO]]");
              },
            }),
            editor.ui.registry.addButton("entrega", {
              text: "FECHA_ENTREGA",
              onAction: function (_) {// eslint-disable-line
                editor.insertContent("[[FECHA_ENTREGA]]");
              },
            });
          editor.ui.registry.addButton("tiempo", {
            text: "TIEMPO_ENTREGA",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[TIEMPO_ENTREGA]]");
            },
          });
          editor.ui.registry.addButton("url_pedido_sp", {
            text: "URL SEGUIMIENTO",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[URL_SEGUIMIENTO]]");
            },
          });
          editor.ui.registry.addButton("code_pedido_sp", {
            text: "CODIGO SEGUIMIENTO",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[CODE_SEGUIMIENTO]]");
            },
          });
          editor.ui.registry.addButton("observaciones_movil", {
            text: "OBSERVACIONES MOVIL",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[OBSERVACIONES]]");
            },
          });
          editor.ui.registry.addButton("placa", {
            text: "PLACA",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[PLACA]]");
            },
          });
          editor.ui.registry.addButton("nombre_conductor", {
            text: "NOMBRE CONDUCTOR",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[CONDUCTOR]]");
            },
          });
          editor.ui.registry.addButton("novedad_sms", {
            text: "NOVEDAD",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[NOVEDAD]]");
            },
          });
          editor.ui.registry.addButton("cedi_pedido", {
            text: "CEDI",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[CEDI]]");
            },
          });
          editor.ui.registry.addButton("observaciones_plataforma", {
            text: "OBSERVACIONES PLATAFORMA",
            onAction: function (_) {// eslint-disable-line
              editor.insertContent("[[OBSERVACIONES_PLATAFORMA]]");
            },
          });
        },
      },
      toolbar: "nombre apellido pedido estado entrega tiempo url_pedido_sp guia code_pedido_sp observaciones_movil placa nombre_conductor novedad_sms cedi_pedido observaciones_plataforma",
      infoData: "Escribe aqui tu mensaje",
      plugins: [],
      options: {
        language_url: require("../../langs/es"), //This url points to location of persian language file.
      },
      i18n: i18n,
      company: null,
      company_id: null,
      companies: [],
      statuses: [],
      status: null,
      status_id: null,
      version_id: null,
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", this.sms);
          this.$store.dispatch("sms/set_sms", null);
        }
      },
    }
  },
  watch: {
    company(val) {
      if (val != null) {
        this.company_id = this.company.id
        this.getStatusCompany(val.version, this.status_id)
      }
    },
    status(val) {
      if (val != null) {
        this.status_id = this.status.id
      }
    },
    sms(val) {
      this.clear()
      if (val != null) {
        this.infoData = val.message
        this.company_id = val.company_id
        this.status_id = val.status_id
        if (this.company_id) {
          for (let i=0; i < this.companies.length; i++) {
            if (this.company_id == this.companies[i].id) {
              this.company = this.companies[i]
            }
          }
        }
      }
    },
  },
  methods: {
    getStatusCompany(id, id_status) {
      StatusServices.getStatus(id)
        .then((response) => {
          this.statuses = response.data.map((row) => ({
            id: row.id,
            name: row.status,
          }))
          if (id_status) {
            for (let i=0; i < this.statuses.length; i++) {
              if (id_status == this.statuses[i].id) {
                this.status = this.statuses[i]
              }
            }
          }
        })
        .catch((error) => { /*eslint-disable-line */
        })
    },
    getCompany() {
      HTTP.get("/v1/companies/companies/").then((response) => {
        this.companies = response.data.map((row) => ({
          id: row.id,
          name: row.company,
          version: row.version
        }))
      });
    },
    clear() {
      this.company = null;
      this.company_id = null;
      this.status_id = null;
      this.status = [];
      this.infoData = "Escribe aqui tu mensaje";
      this.$validator.reset()
    },
    setSave() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let dataFormated = {
            status: this.status.id,
            infoData: HtmlEntitiesencode(this.infoData.replace(/<p[^>]*>/g, "").replace(/<\/p>/g, "")),
            company_id: this.company.id,
          };
          if (this.sms == null) {
            this.createSms(dataFormated);
          } else {
            this.updateSms(dataFormated);
          }
        }
      });
    },
    updateSms(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = `v1/sms/update/${this.sms.id_sms}`
          HTTP.setHeader(true)
          HTTP.put(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("sms.update"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.clear();
              this.popup = false;
              this.$emit("refreshTable");
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
            });
        }
      });
    },
    createSms(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = "/v1/sms/create";
          HTTP.setHeader(true);
          HTTP.post(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("sms.create"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.clear();
              this.$emit("refreshTable");
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
            });
        }
      });
    },
  },
  created() {
    if (!this.companies.length) this.getCompany()
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupStatusForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  }
}
function HtmlEntitiesencode(string) {
    var HtmlEntities = function() {};
    HtmlEntities.map = {
      "'": "&apos;",
      "<": "&lt;",
      ">": "&gt;",
      " ": "&nbsp;",
      "¡": "&iexcl;",
      "¢": "&cent;",
      "£": "&pound;",
      "¤": "&curren;",
      "¥": "&yen;",
      "¦": "&brvbar;",
      "§": "&sect;",
      "¨": "&uml;",
      "©": "&copy;",
      "ª": "&ordf;",
      "«": "&laquo;",
      "¬": "&not;",
      "®": "&reg;",
      "¯": "&macr;",
      "°": "&deg;",
      "±": "&plusmn;",
      "²": "&sup2;",
      "³": "&sup3;",
      "´": "&acute;",
      "µ": "&micro;",
      "¶": "&para;",
      "·": "&middot;",
      "¸": "&cedil;",
      "¹": "&sup1;",
      "º": "&ordm;",
      "»": "&raquo;",
      "¼": "&frac14;",
      "½": "&frac12;",
      "¾": "&frac34;",
      "¿": "&iquest;",
      "À": "&Agrave;",
      "Á": "&Aacute;",
      "Â": "&Acirc;",
      "Ã": "&Atilde;",
      "Ä": "&Auml;",
      "Å": "&Aring;",
      "Æ": "&AElig;",
      "Ç": "&Ccedil;",
      "È": "&Egrave;",
      "É": "&Eacute;",
      "Ê": "&Ecirc;",
      "Ë": "&Euml;",
      "Ì": "&Igrave;",
      "Í": "&Iacute;",
      "Î": "&Icirc;",
      "Ï": "&Iuml;",
      "Ð": "&ETH;",
      "Ñ": "&Ntilde;",
      "Ò": "&Ograve;",
      "Ó": "&Oacute;",
      "Ô": "&Ocirc;",
      "Õ": "&Otilde;",
      "Ö": "&Ouml;",
      "×": "&times;",
      "Ø": "&Oslash;",
      "Ù": "&Ugrave;",
      "Ú": "&Uacute;",
      "Û": "&Ucirc;",
      "Ü": "&Uuml;",
      "Ý": "&Yacute;",
      "Þ": "&THORN;",
      "ß": "&szlig;",
      "à": "&agrave;",
      "á": "&aacute;",
      "â": "&acirc;",
      "ã": "&atilde;",
      "ä": "&auml;",
      "å": "&aring;",
      "æ": "&aelig;",
      "ç": "&ccedil;",
      "è": "&egrave;",
      "é": "&eacute;",
      "ê": "&ecirc;",
      "ë": "&euml;",
      "ì": "&igrave;",
      "í": "&iacute;",
      "î": "&icirc;",
      "ï": "&iuml;",
      "ð": "&eth;",
      "ñ": "&ntilde;",
      "ò": "&ograve;",
      "ó": "&oacute;",
      "ô": "&ocirc;",
      "õ": "&otilde;",
      "ö": "&ouml;",
      "÷": "&divide;",
      "ø": "&oslash;",
      "ù": "&ugrave;",
      "ú": "&uacute;",
      "û": "&ucirc;",
      "ü": "&uuml;",
      "ý": "&yacute;",
      "þ": "&thorn;",
      "ÿ": "&yuml;",
      "Œ": "&OElig;",
      "œ": "&oelig;",
      "Š": "&Scaron;",
      "š": "&scaron;",
      "Ÿ": "&Yuml;",
      "ƒ": "&fnof;",
      "ˆ": "&circ;",
      "˜": "&tilde;",
      "Α": "&Alpha;",
      "Β": "&Beta;",
      "Γ": "&Gamma;",
      "Δ": "&Delta;",
      "Ε": "&Epsilon;",
      "Ζ": "&Zeta;",
      "Η": "&Eta;",
      "Θ": "&Theta;",
      "Ι": "&Iota;",
      "Κ": "&Kappa;",
      "Λ": "&Lambda;",
      "Μ": "&Mu;",
      "Ν": "&Nu;",
      "Ξ": "&Xi;",
      "Ο": "&Omicron;",
      "Π": "&Pi;",
      "Ρ": "&Rho;",
      "Σ": "&Sigma;",
      "Τ": "&Tau;",
      "Υ": "&Upsilon;",
      "Φ": "&Phi;",
      "Χ": "&Chi;",
      "Ψ": "&Psi;",
      "Ω": "&Omega;",
      "α": "&alpha;",
      "β": "&beta;",
      "γ": "&gamma;",
      "δ": "&delta;",
      "ε": "&epsilon;",
      "ζ": "&zeta;",
      "η": "&eta;",
      "θ": "&theta;",
      "ι": "&iota;",
      "κ": "&kappa;",
      "λ": "&lambda;",
      "μ": "&mu;",
      "ν": "&nu;",
      "ξ": "&xi;",
      "ο": "&omicron;",
      "π": "&pi;",
      "ρ": "&rho;",
      "ς": "&sigmaf;",
      "σ": "&sigma;",
      "τ": "&tau;",
      "υ": "&upsilon;",
      "φ": "&phi;",
      "χ": "&chi;",
      "ψ": "&psi;",
      "ω": "&omega;",
      "ϑ": "&thetasym;",
      "ϒ": "&Upsih;",
      "ϖ": "&piv;",
      "–": "&ndash;",
      "—": "&mdash;",
      "‘": "&lsquo;",
      "’": "&rsquo;",
      "‚": "&sbquo;",
      "“": "&ldquo;",
      "”": "&rdquo;",
      "„": "&bdquo;",
      "†": "&dagger;",
      "‡": "&Dagger;",
      "•": "&bull;",
      "…": "&hellip;",
      "‰": "&permil;",
      "′": "&prime;",
      "″": "&Prime;",
      "‹": "&lsaquo;",
      "›": "&rsaquo;",
      "‾": "&oline;",
      "⁄": "&frasl;",
      "€": "&euro;",
      "ℑ": "&image;",
      "℘": "&weierp;",
      "ℜ": "&real;",
      "™": "&trade;",
      "ℵ": "&alefsym;",
      "←": "&larr;",
      "↑": "&uarr;",
      "→": "&rarr;",
      "↓": "&darr;",
      "↔": "&harr;",
      "↵": "&crarr;",
      "⇐": "&lArr;",
      "⇑": "&UArr;",
      "⇒": "&rArr;",
      "⇓": "&dArr;",
      "⇔": "&hArr;",
      "∀": "&forall;",
      "∂": "&part;",
      "∃": "&exist;",
      "∅": "&empty;",
      "∇": "&nabla;",
      "∈": "&isin;",
      "∉": "&notin;",
      "∋": "&ni;",
      "∏": "&prod;",
      "∑": "&sum;",
      "−": "&minus;",
      "∗": "&lowast;",
      "√": "&radic;",
      "∝": "&prop;",
      "∞": "&infin;",
      "∠": "&ang;",
      "∧": "&and;",
      "∨": "&or;",
      "∩": "&cap;",
      "∪": "&cup;",
      "∫": "&int;",
      "∴": "&there4;",
      "∼": "&sim;",
      "≅": "&cong;",
      "≈": "&asymp;",
      "≠": "&ne;",
      "≡": "&equiv;",
      "≤": "&le;",
      "≥": "&ge;",
      "⊂": "&sub;",
      "⊃": "&sup;",
      "⊄": "&nsub;",
      "⊆": "&sube;",
      "⊇": "&supe;",
      "⊕": "&oplus;",
      "⊗": "&otimes;",
      "⊥": "&perp;",
      "⋅": "&sdot;",
      "⌈": "&lceil;",
      "⌉": "&rceil;",
      "⌊": "&lfloor;",
      "⌋": "&rfloor;",
      "⟨": "&lang;",
      "⟩": "&rang;",
      "◊": "&loz;",
      "♠": "&spades;",
      "♣": "&clubs;",
      "♥": "&hearts;",
      "♦": "&diams;"
  };
        var entityMap = HtmlEntities.map;
    for (var key in entityMap) {
        var entity = entityMap[key];
        var regex = new RegExp(entity, 'g');
        string = string.replace(regex, key);
    }
    string = string.replace(/&quot;/g, '"');
    string = string.replace(/&amp;/g, '&');
    return string;
}
</script>

<style>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
